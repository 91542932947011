export const colors = {
  // Main backgrounds
  background: '#f0f4f8',      // Cool light blue-gray - easy on eyes
  surface: '#ffffff',         // Pure white for content
  surfaceLight: '#f8fafc',    // Subtle blue tint for elevated surfaces
  
  // Text colors
  headline: '#1a365d',        // Deep blue - for strong headlines
  paragraph: '#2d4a6d',       // Softer blue - for readable text
  textMuted: '#64748b',       // Muted blue-gray
  
  // Primary colors - Teal represents growth and learning
  primary: '#0694a2',         // Vibrant teal - main actions
  primaryHover: '#047481',    // Darker teal
  primaryMuted: '#a5f3fc',    // Light teal for subtle elements
  
  // Secondary colors - Complementary to teal
  secondary: '#7c3aed',       // Purple - for visual hierarchy
  secondaryHover: '#6d28d9',  // Darker purple
  secondaryMuted: '#ddd6fe',  // Light purple
  
  // Accent colors - For highlights and emphasis
  accent: '#0ea5e9',         // Sky blue - for highlights
  accentHover: '#0284c7',    // Darker sky blue
  accentMuted: '#bae6fd',    // Light sky blue
  
  // Status colors - Clear and intuitive
  success: '#059669',        // Green - success states
  successHover: '#047857',
  danger: '#dc2626',         // Red - errors/warnings
  dangerHover: '#b91c1c',
  warning: '#d97706',        // Orange - caution states
  warningHover: '#b45309',
  
  // Borders and shadows - Subtle depth
  border: '#e2e8f0',         // Light blue-gray border
  borderLight: '#f1f5f9',    // Very light border
  shadow: 'rgba(51, 65, 85, 0.08)',
  shadowHover: 'rgba(51, 65, 85, 0.12)',
  
  // Gradients
  gradient: {
    primary: 'linear-gradient(135deg, #0694a2, #0ea5e9)',
    secondary: 'linear-gradient(135deg, #7c3aed, #0ea5e9)'
  }
}; 